import React, { useCallback, useState, useEffect } from 'react';
import useCurrentPage from '../Shared/Hooks/useCurrentPage';
import { media, pseudo } from '@glitz/core';
import { applyEditModeAttr } from '../../Features/Shared/Common/Helpers';
import { styled, theme } from '../Theme';
import OrderHistoryPageModel from './Models/OrderHistoryPageModel.interface';
import Pagination from './../Shared/Pagination/Pagination';
import { FetchPagination } from '../Shared/Pagination/PaginationFetcher';
import OrderHistoryPaginationItemViewModel from './Models/OrderHistoryPaginationItemViewModel.interface';
import SearchComponent from './../Shared/SearchComponent/SearchComponent';
import { GenericDropDown } from '../Shared/ValueDropDown/GenericDropDown';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';
import {
  mapObjectStringTranslations,
  mapObjectStringTranslation,
} from './../Shared/Common/Translate';
import TepeList from '../TepeList/TepeList';
import OrderHistoryListItem from '../TepeList/OrderHistoryListItem';
import useMedia from '../Shared/Hooks/useMedia';
import KexLink from '../Shared/KexLink/KexLink';
import { CloseIcon } from '../Shared/Icons';

type PaginationDataType = {
  paginationItems: OrderHistoryPaginationItemViewModel[];
  totalRecords: number;
};

function OrderHistoryPage() {
  const {
    pageHeading,
    inEditMode,
    paginationType,
    orderStatusFilter,
    monthFilter,
    dateFilter,
    yearFilter,
  } = useCurrentPage<OrderHistoryPageModel>();
  const {
    translations: {
      'common/search': searchLabel,
      'orderHistoryPage/orderNumber': orderNumberLabel,
      'orderHistoryPage/orderNote': orderNoteLabel,
      'orderHistoryPage/status': statusLabel,
      'orderHistoryPage/placed': placedLabel,
      'orderHistoryPage/orderLines': orderLinesLabel,
      'orderHistoryPage/total': totalLabel,
      'orderHistoryPage/DateFilter/NewToOld': newToOld,
      'orderHistoryPage/moreFilters': moreFiltersLabel,
      'orderHistoryPage/lessFilters': lessFiltersLabel,
      'orderHistoryPage/listOrdersBy': listOrdersByLabel,
      'orderHistoryPage/findOrdersBy': findOrdersByLabel,
      'orderHistoryPage/byStatus': byStatusLabel,
      'orderHistoryPage/forYear': forYearLabel,
      'orderHistoryPage/forMonth': forMonthLabel,
      'orderHistoryPage/perPage': perPageLabel,
      'orderHistoryPage/All': allValue,
    },
    translations,
    currency,
    languageRoute,
  } = useAppSettingsData();
  const [orderItems, setOrderItems] = useState<
    OrderHistoryPaginationItemViewModel[]
  >([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [currentQuery, setQuery] = useState<string>('');
  const [currentDateFilter, setCurrentDateFilter] = useState<string>(newToOld);
  const [currentStatusFilter, setCurrentStatusFilter] = useState<string>('0');
  const [currentMonthFilter, setCurrentMonthFilter] = useState<string>('0');
  const [currentYearFilter, setCurrentYearFilter] = useState<string>(allValue);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>('error');
  const [currentPageSizeFilter, setCurrentPageSizeFilter] = useState<string>(
    '10'
  );
  const isMobile = useMedia(theme.mediaQuery.mediaMaxLarge);
  const [showMoreFilters, setShowMoreFilters] = useState<boolean>(
    isMobile ? false : true
  );
  const onFetchError = useCallback((message: string) => {
    setError(true);
    setErrorText(message);
    setTimeout(() => {
      setErrorText('');
      setError(false);
    }, 5000);
  }, []);

  const onFetchResult = useCallback((data: PaginationDataType) => {
    setIsLoading(false);
    setOrderItems(data.paginationItems);
    setTotalRecords(data.totalRecords);
  }, []);

  const buildFilter = useCallback(
    () => ({
      status: currentStatusFilter,
      month: currentMonthFilter,
      year: currentYearFilter,
      orderByDateDescending: currentDateFilter === newToOld ? true : false,
      query: currentQuery,
    }),
    [
      currentStatusFilter,
      currentQuery,
      currentDateFilter,
      currentMonthFilter,
      currentYearFilter,
      newToOld,
    ]
  );

  const toggleShowFilers = useCallback(() => {
    setShowMoreFilters(!showMoreFilters);
  }, [showMoreFilters]);

  const fetchPagination = useCallback(
    (page?: number) => {
      setIsLoading(true);
      FetchPagination(
        paginationType,
        page || 0,
        +currentPageSizeFilter,
        buildFilter(),
        onFetchResult,
        onFetchError,
        languageRoute
      );
    },
    [
      paginationType,
      onFetchResult,
      onFetchError,
      buildFilter,
      currentPageSizeFilter,
      languageRoute,
    ]
  );

  const onPageChange = useCallback(
    (page: number) => {
      fetchPagination(page);
    },
    [fetchPagination]
  );

  useEffect(() => {
    fetchPagination();
  }, [fetchPagination]);

  const searchQuery = useCallback(query => {
    setQuery(query);
  }, []);

  return (
    <Root>
      <TopContent>
        <Heading {...applyEditModeAttr(inEditMode && 'PageHeading')}>
          {pageHeading}
        </Heading>
        {error && <InputError>{errorText}</InputError>}
        <Filters>
          <LeftContainer>
            <SearchInput>
              <SearchComponent
                searchQuery={searchQuery}
                placeHolder={orderNumberLabel}
                buttonText={searchLabel}
                isMobile={isMobile}
                label={findOrdersByLabel}
              />
            </SearchInput>
            <DateFilterContainer>
              <GenericDropDown
                values={mapObjectStringTranslations(translations, dateFilter)}
                currentValue={currentDateFilter}
                setSelectedValue={setCurrentDateFilter}
                label={listOrdersByLabel}
              />
            </DateFilterContainer>
          </LeftContainer>
          <RightContainer>
            <StatusFilterContainer>
              <GenericDropDown
                values={mapObjectStringTranslations(
                  translations,
                  orderStatusFilter
                )}
                returnIndex
                currentValue={mapObjectStringTranslation(
                  translations,
                  orderStatusFilter[parseInt(currentStatusFilter)]
                )}
                setSelectedValue={setCurrentStatusFilter}
                label={byStatusLabel}
              />
            </StatusFilterContainer>
            {showMoreFilters && (
              <ExtraFilters>
                <MonthFilterContainer>
                  <GenericDropDown
                    values={mapObjectStringTranslations(
                      translations,
                      monthFilter
                    )}
                    returnIndex
                    currentValue={mapObjectStringTranslation(
                      translations,
                      monthFilter[parseInt(currentMonthFilter)]
                    )}
                    setSelectedValue={setCurrentMonthFilter}
                    label={forMonthLabel}
                  />
                </MonthFilterContainer>
                <DropDownWrapper>
                  <GenericDropDown
                    values={mapObjectStringTranslations(
                      translations,
                      yearFilter
                    )}
                    currentValue={currentYearFilter}
                    setSelectedValue={setCurrentYearFilter}
                    label={forYearLabel}
                  />
                </DropDownWrapper>
                <DropDownWrapper>
                  <GenericDropDown
                    values={['10', '20', '30', '50']}
                    currentValue={currentPageSizeFilter}
                    setSelectedValue={setCurrentPageSizeFilter}
                    label={perPageLabel}
                  />
                </DropDownWrapper>
              </ExtraFilters>
            )}
            {isMobile && (
              <ToggleFilterWrapper>
                <StyledLink onClick={toggleShowFilers}>
                  {showMoreFilters ? lessFiltersLabel : moreFiltersLabel}
                  <ToggleIcon
                    css={{
                      transform: showMoreFilters ? 'none' : 'rotate(45deg)',
                      transition: {
                        property: 'transform',
                        duration: theme.timings.oneHalf,
                      },
                    }}
                  />
                </StyledLink>
              </ToggleFilterWrapper>
            )}
          </RightContainer>
        </Filters>

        <TepeList
          isLoading={isLoading}
          headers={[
            orderNumberLabel,
            placedLabel,
            orderNoteLabel,
            statusLabel,
            orderLinesLabel,
            `${totalLabel} - ${currency}`,
          ]}
        >
          {orderItems &&
            orderItems.map((item: OrderHistoryPaginationItemViewModel) => (
              <OrderHistoryListItem
                key={item.orderNumber}
                item={item}
                headers={[
                  orderNumberLabel,
                  placedLabel,
                  orderNoteLabel,
                  statusLabel,
                  orderLinesLabel,
                  totalLabel,
                ]}
              />
            ))}
        </TepeList>
        {Boolean(totalRecords) && (
          <Pagination
            totalRecords={totalRecords}
            numberOfRecords={+currentPageSizeFilter}
            onPageChange={onPageChange}
          />
        )}
      </TopContent>
    </Root>
  );
}

const TopContent = styled.div({
  margin: { y: theme.spacing(6), x: 'auto' },
  width: '100%',

  ...media(theme.mediaQuery.mediaMinLarge, {
    margin: { y: theme.spacing(13) },
  }),
  ...media(theme.mediaQuery.mediaMinMedium, {
    margin: { xy: 'auto' },
    maxWidth: theme.rowSectionContentMaxWidth,
  }),
  marginBottom: theme.spacing(4),
});

const StyledLink = styled(KexLink, {
  display: 'flex',
  alignItems: 'center',
});

const DateFilterContainer = styled.div({
  ...media(theme.mediaQuery.mediaMinLarge, {
    maxWidth: theme.spacing(76),
  }),
});

const StatusFilterContainer = styled.div({
  width: '100%',
  ...media(theme.mediaQuery.mediaMinLarge, {
    maxWidth: '33%',
  }),
});

const MonthFilterContainer = styled.div({
  ...media(theme.mediaQuery.mediaMinLarge, {
    width: '33%',
    marginRight: theme.spacing(16),
  }),
});

const Root = styled.div({
  color: theme.black,
  padding: { x: theme.spacing(4) },
});

const Heading = styled.h2({
  font: { size: theme.zeta, weight: theme.fontWeight.bold },
  marginBottom: theme.spacing(5.5),
  ...media(theme.mediaQuery.mediaMinLarge, {
    font: { size: theme.zeta },
    marginBottom: theme.spacing(8),
  }),
});

const ToggleFilterWrapper = styled.div({
  fontWeight: theme.fontWeight.bold,
  color: theme.blueDark,
  display: 'flex',
  justifyContent: 'end',
});

const RightContainer = styled.div({
  ...media(theme.mediaQuery.mediaMaxMedium, {
    margin: { xy: theme.none },
    width: '100%',
  }),
  width: '100%',
});

const LeftContainer = styled.div({
  ...media(theme.mediaQuery.mediaMaxMedium, {
    width: '100%',
  }),
  width: '100%',
  marginRight: theme.spacing(16),
});

const DropDownWrapper = styled.div({
  ...media(theme.mediaQuery.mediaMinLarge, {
    ...pseudo(':not(:last-child)', {
      margin: { right: theme.spacing(16) },
    }),
  }),
});

const ToggleIcon = styled(CloseIcon, {
  marginLeft: theme.spacing(2),
  color: theme.blueDark,
  fill: theme.blueDark,
  width: theme.spacing(4),
  height: theme.spacing(4),
});

const Filters = styled.div({
  ...media(theme.mediaQuery.mediaMaxMedium, { display: 'block' }),
  display: 'flex',
  marginBottom: theme.spacing(8),
});

const SearchInput = styled.div({
  marginBottom: theme.spacing(4),
  ...media(theme.mediaQuery.mediaMinMedium, {
    maxWidth: theme.spacing(114),
    width: '100%',
  }),
  width: '100%',
});

const ExtraFilters = styled.div({
  display: 'flex',
  flexDirection: 'column',
  ...media(theme.mediaQuery.mediaMinLarge, {
    flexDirection: 'row',
  }),
});

const InputError = styled.div({
  border: {
    xy: {
      color: theme.errorPrimary,
    },
  },
  backgroundColor: theme.errorPrimary,
  padding: { xy: theme.spacing(4) },
  color: theme.errorText,
  marginBottom: theme.spacing(4),
});

export default OrderHistoryPage;
