import React from 'react';
import { media } from '@glitz/core';
import { styled, theme } from '../Theme';
import useMedia from '../Shared/Hooks/useMedia';
import OrderHistoryPaginationItemViewModel from '../OrderHistoryPage/Models/OrderHistoryPaginationItemViewModel.interface';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';
import { useKexNavigate } from '../Shared/KexRouter/KexRouter';
import IconLink from '../Shared/IconLink/IconLink';
import { LinkType } from '../Enums/LinkType.enum';

type PropTypes = {
  item: OrderHistoryPaginationItemViewModel;
  headers: string[];
};

function OrderHistoryListItem({ item, headers }: PropTypes) {
  const isMobile = useMedia(theme.mediaQuery.mediaMaxLarge);
  const {
    translations: { 'orderHistoryPage/viewOrder': viewOrderLabel },
    staticPages: { orderPage },
    translations,
  } = useAppSettingsData();
  const kexNavigate = useKexNavigate();
  const URLToOrder = `${orderPage}?orderNumber=${item.orderNumber}`;
  const navigateIfDektop = () => {
    !isMobile && kexNavigate(URLToOrder);
  };
  return (
    <Row
      onClick={() => {
        navigateIfDektop();
      }}
    >
      <TableData>
        {isMobile && headers[0] + ':'}
        <Value>
          <Spacing>{item.orderNumber}</Spacing>
        </Value>
      </TableData>
      <TableData>
        {isMobile && headers[1]}
        <Value>{item.placed}</Value>
      </TableData>
      <TableDataOrderNote>
        {isMobile && headers[2]}
        <Value> {item.orderNote}</Value>
      </TableDataOrderNote>
      <TableData>
        {isMobile && headers[3]}
        <Value css={{ whiteSpace: 'nowrap' }}>
          {translations[item.status]}
        </Value>
      </TableData>
      {!isMobile && (
        <>
          <TableData>
            <Value> {item.orderLines}</Value>
          </TableData>
          <TableData>
            <Value> {item.total}</Value>
          </TableData>
        </>
      )}
      {isMobile && (
        <TableData
          css={{ display: 'flex', justifyContent: 'end', fontSize: theme.beta }}
        >
          <IconLink
            css={{ fontSize: theme.beta }}
            href={URLToOrder}
            type={LinkType.Arrow}
          >
            {viewOrderLabel}
          </IconLink>
        </TableData>
      )}
    </Row>
  );
}

const Spacing = styled.div({});

const Value = styled.div({
  ...media(theme.mediaQuery.mediaMinLarge, {
    margin: { y: theme.spacing(4) },
  }),
  lineBreak: 'anywhere',
  marginLeft: theme.spacing(1),
});
const TableData = styled.td({
  ...media(theme.mediaQuery.mediaMaxLarge, {
    display: 'flex',
    fontWeight: theme.fontWeight.bold,
    margin: { y: theme.spacing(2) },
  }),
  wordWrap: 'break-word',
});

const TableDataOrderNote = styled(TableData, {
  ...media(theme.mediaQuery.mediaMinLarge, {
    maxWidth: '35%',
    width: '100%',
  }),
});

const Row = styled.tr({
  borderBottomWidth: theme.tiny,
  borderBottomColor: theme.lightGray,
  ...media(theme.mediaQuery.mediaMaxLarge, {
    display: 'grid',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  }),
  ':hover': {
    cursor: 'pointer',
  },
});

export default OrderHistoryListItem;
